import $ from "jquery";

$(document).on("keyup", "input[data-battery-type-key]", function(e) {
  const stockCount = $(`[data-battery-type-key="${$(this).data('battery-type-key')}"][data-battery-type-stock]`)
  if (stockCount.length) {
    const value = this.value || 0;
    const newCount = stockCount.data('battery-type-stock') + parseInt(value);
    stockCount.text(newCount);
  }
});
