import $ from 'jquery'

$(document).on('keyup', '[data-expose-filter]', (e) => {
  const inputId = $(e.target).data('expose-filter');
  const $exposedInput = $(`#${inputId}`);

  if (e.key === 'Enter') {
    $exposedInput.closest('form').trigger('submit');
  } else {
    $exposedInput.val(e.target.value);
  }
});
