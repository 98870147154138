import $ from 'jquery'
import feather from "feather-icons";
import fieldsTemplate from 'templates/battery-return-item-fields.handlebars'

$(document).on('click', '.add-battery-return-item', function(e) {
  e.preventDefault();
  const fields = fieldsTemplate({ timestamp: Date.now() });
  $('.battery-return-items').append(fields);
  feather.replace();
})

$(document).on('click', '.remove-battery-return-item', function(e) {
  e.preventDefault();
  if ($(this).data('persisted')) {
    removePersistedReturn($(this));
  } else {
    $(this).closest('.battery-return-item-fields').remove();
  }
});

function removePersistedReturn($return) {
  $return.next('.destroy-field').val('true');
  $return.closest('.battery-return-item-fields').addClass('d-none');
}
