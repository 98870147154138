import $ from "jquery";
import consumer from "channels/consumer";
import feather from "feather-icons";

class ProductImportTracker {
  constructor() {
    this.setupCompleted = false;
  }

  setup(jobIds) {
    if (!this.setupCompleted) {
      this.createSubscription(jobIds);
      this.setupCompleted = true;
    }
  }

  createSubscription(jobIds) {
    consumer.subscriptions.create(
      {
        channel: "ProductImportJobsChannel",
        job_ids: jobIds,
      },
      {
        received: this.received.bind(this),
      }
    );
  }

  received(data) {
    const wrapper = $(`[data-job-id="${data.id}"]`);
    wrapper.html(data.card_html);
    feather.replace();
  }
}

const tracker = new ProductImportTracker();

function collectJobIds() {
  const jobIds = [];
  $('[data-track-import-job="true"]').each(function () {
    jobIds.push($(this).data("jobId"));
  });

  return jobIds;
}

function ready() {
  const jobIds = collectJobIds();

  if (jobIds.length > 0) {
    tracker.setup(jobIds);
  }
}

$(document).on("turbolinks:load", ready);
