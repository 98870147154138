import $ from 'jquery'

$(document).on('change', '.print-record-checkbox', function(e) {
  const button = $('.btn-print');
  const checked = $('.print-record-checkbox:checked')

  if (checked.length > 0) {
    button.attr('disabled', false)
  } else {
    button.attr('disabled', true)
  }
});

$(document).on('submit', '.print-records-form', function (e) {
  const form = $(this);
  $('.print-record-checkbox:checked').each(function() {
    const checkbox = $(this);

    const input = $('<input />').
      attr('type', 'hidden').
      attr('name', checkbox.attr('name')).
      val(checkbox.val());

    input.appendTo(form);
  });
});
