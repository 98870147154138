import "@selectize/selectize"
import $ from 'jquery'

function ready() {
  $('.selectize').selectize();
}

function unselectize() {
  $('.selectize').each(function() {
    this.selectize.destroy();
  });
}

$(document).on('turbolinks:load', ready);
$(document).on('turbolinks:before-cache', unselectize);
