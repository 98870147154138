import $ from 'jquery';

$(document).on('change', '.battery-return-checkbox', function(e){
  const actions = $('#batch-actions');
  const checked = $('.battery-return-checkbox:checked');
  $('#return-count').text(checked.length);

  if (checked.length > 0) {
    actions.addClass('show');
  } else {
    actions.removeClass('show');
  }
})

$(document).on('click', '.batch-action', function(e){
  const action = $(this).data('action');
  const token = $(this).data('token');
  const form = $('#batch-action-form');

  form.find('input[name="authenticity_token"]').val(token);
  form.attr('action', action).submit();
});
