import $ from 'jquery'

function currency(number) {
  const rounded = number.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  return '$' + rounded;
}

class CreditNoteForm {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.updateTotals = this.updateTotals.bind(this);
  }

  setup() {
    this.subtotal = this.wrapper.find('#credit-note-subtotal');
    this.tax = this.wrapper.find('#credit-note-tax');
    this.total = this.wrapper.find('#credit-note-total');

    this.wrapper.find('.credit-note-item-quantity').keyup(this.updateTotals);
    this.wrapper.find('.credit-note-item-price').keyup(this.updateTotals);
    this.wrapper.find('.credit-note-item-price').change(this.updateTotals);

    this.updateTotals();
  }

  updateTotals() {
    const subtotal = this.computeSubtotal();
    const tax = subtotal * 0.16;
    const total = subtotal + tax;

    this.subtotal.text(currency(subtotal));
    this.tax.text(currency(tax));
    this.total.text(currency(total));
  }

  computeSubtotal() {
    let subtotal = 0;

    this.wrapper.find('.credit-note-item').each(function(){
      const quantity = $(this).find('.credit-note-item-quantity').val();
      const price = $(this).find('.credit-note-item-price').val();

      if (quantity && price) {
        subtotal += parseInt(quantity) * parseFloat(price);
      }
    });

    return subtotal;
  }
}

function ready() {
  const wrapper = $('#credit-note-form');
  if (wrapper.length > 0) {
    const form = new CreditNoteForm(wrapper);
    form.setup();
  }
}

$(document).on('turbolinks:load', ready);
