import $ from 'jquery';

$(document).on('click', '.battery-return-export', function(e) {
  const url = $(this).data('url');
  const token = $(this).data('token');

  const input = $('<input />').
    attr('type', 'hidden').
    attr('name', 'authenticity_token').
    val(token);

  const form = $('#battery-return-search-form').clone();
  form.append(input);
  form.attr('action', url).attr('method', 'post');

  $('body').append(form);
  form.submit().remove();
});
