// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import feather from "feather-icons";
import "channels";
import "jquery";
import "popper.js";
import "bootstrap";

import "components/selectize";
import "components/select-all";
import "components/sidebar";
import "components/orders-table";
import "components/import-jobs";
import "components/battery-return-form";
import "components/battery-return-approvals";
import "components/battery-return-export";
import "components/battery-stock-adjustment-form";
import "components/credit-note-form";
import "components/datepicker";
import "components/exposed-filters";
import "components/dropdown";

import "stylesheets/application";

document.addEventListener("turbolinks:load", function() {
  feather.replace();
});

Rails.start();
Turbolinks.start();
ActiveStorage.start();
