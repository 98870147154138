import $ from 'jquery';

$(document).on('hide.bs.dropdown', function(e){
  const dropdown = e.target;

  if (
    e.clickEvent &&
    dropdown.dataset.withFlatpickr &&
    $(e.clickEvent.target).closest('.flatpickr-calendar').length
  ) {
    e.preventDefault();
  }
});
