var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row align-items-center battery-return-item-fields\">\n  <div class=\"form-group col-6 col-sm-7\">\n    <select class=\"form-control\" name=\"battery_return[battery_return_items_attributes]["
    + alias4(((helper = (helper = lookupProperty(helpers,"timestamp") || (depth0 != null ? lookupProperty(depth0,"timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":3,"column":87},"end":{"line":3,"column":100}}}) : helper)))
    + "][battery_type_key]\" required=\"required\">\n      <option value=\"\">Por favor selecciona</option>\n      <option value=\"grupo_1\">Grupo 1</option>\n      <option value=\"grupo_2\">Grupo 2</option>\n      <option value=\"grupo_3\">Grupo 3</option>\n      <option value=\"grupo_4\">Grupo 4</option>\n      <option value=\"grupo_5\">Grupo 5</option>\n      <option value=\"grupo_6\">Grupo 6</option>\n      <option value=\"grupo_7\">Grupo 7</option>\n      <option value=\"motos\">Motocicletas</option>\n    </select>\n  </div>\n  <div class=\"form-group col-4\">\n    <input type=\"number\" name=\"battery_return[battery_return_items_attributes]["
    + alias4(((helper = (helper = lookupProperty(helpers,"timestamp") || (depth0 != null ? lookupProperty(depth0,"timestamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":16,"column":79},"end":{"line":16,"column":92}}}) : helper)))
    + "][quantity]\" class=\"form-control\" step=\"1\" required=\"required\">\n  </div>\n  <div class=\"form-group col-2 col-sm-1 text-center\">\n    <a href=\"#\" class=\"remove-battery-return-item\">\n      <i data-feather=\"x\" width=\"18\" height=\"18\"></i>\n    </a>\n  </div>\n</div>\n";
},"useData":true});